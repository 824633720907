/* eslint-disable quotes */
export const triggersContent = [
  {
    "type": "cron",
    "id": "myCron",
    "test_event": "", // file_uuid
    "command": "", // eg. 'python /path/to/file.py'
    "settings": {
      "freq": "custom",
      "tz": "UTC",
      "crontab": "00**",
    },
  },
];

export const triggers = [{
  "meta": {
    "name": "cron",
    "display_name": "Time Trigger",
  },
  "schema": {
    "root": {
      "children": [
        "freq",
      ],
    },
    "freq": {
      "type": "selector",
      "name": "freq",
      "display_name": "Run Script Every:",
      "default": "day",
      "values": ["minute", "ten_minutes", "hour", "day", "week", "month", "custom"],
      "display_values": ["Minute", "Ten Minutes", "Hour", "Day", "Week", "Month", "Custom (Advanced)"],
      "children": {
        "day": ["time", "tz"],
        "week": ["time", "tz", "weekday"],
        "month": ["time", "tz", "month_day"],
        "custom": ["tz", "crontab"],
      },
    },
    "crontab": {
      "type": "text_input",
      "name": "crontab",
      "display_name": "Crontab Line",
      "default": "",
      "placeholder": "Crontab line (e.g. \"25 * * * *\")",
      "validator": "crontab",
      "regired": true,
    },
    "time": {
      "type": "time_picker",
      "name": "time",
      "display_name": "What Time?",
    },
    "weekday": {
      "type": "selector",
      "name": "weekday",
      "display_name": "What Day of the Week?",
      "default": "",
      "values": ["", "0", "1", "2", "3", "4", "5", "6"],
      "display_values": ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    },
    "month_day": {
      "type": "selector",
      "name": "month_day",
      "display_name": "What Day of the Month?",
      "default": "",
      "values": ["", "first", "last", "1", "2", "3", "4",
        "5", "6", "7", "8", "9", "10", "11", "12",
        "13", "14", "15", "16", "17", "18", "19", "20",
        "21", "22", "23", "24", "25", "26", "27", "28",
        "29", "30", "31",
      ],
      "display_values": ["", "The First", "The Last", "The 1st", "The 2nd", "The 3rd", "The 4th",
        "The 5th", "The 6th", "The 7th", "The 8th", "The 9th", "The 10th", "The 11th", "The 12th",
        "The 13th", "The 14th", "The 15th", "The 16th", "The 17th", "The 18th", "The 19th", "The 20th",
        "The 21th", "The 22th", "The 23th", "The 24th", "The 25th", "The 26th", "The 27th", "The 28th",
        "The 29th", "The 30th", "The 31th",
      ],
    },
    "tz": {
      "type": "selector",
      "name": "tz",
      "display_name": "Time Zone",
      "default": "UTC",
      "values": ["", "UTC", "GMT"],
      "display_values": ["", "UTC", "GMT"],
    },
  },
}];

/* eslint-disable quotes */
export const integrations = [
  {
    "meta": {
      "type": "sql",
      "version": "0.0.0",
    },
    "schema": {
      "database_host": {
        "required": true,
        "type": "text",
        "value": null,
      },
      "database_name": {
        "required": true,
        "type": "text",
        "value": null,
      },
      "database_password": {
        "required": true,
        "type": "text",
        "value": null,
      },
      "database_post": {
        "required": true,
        "type": "text",
        "value": null,
      },
      "database_type": {
        "required": true,
        "type": "text",
        "value": null,
        "values": [
          "postgres",
          "mysql",
          "sql_server",
        ],
      },
      "database_user": {
        "required": true,
        "type": "text",
        "value": null,
      },
      "root": {
        "children": [
          "database_user",
          "database_password",
          "database_name",
          "database_type",
          "database_host",
          "database_post",
        ],
      },
    },
  },
];

export const integrationsContent = [
  {
    "name": "sql",
    "id": "sql",
    "settings": {
      "database_user": "user",
      "database_password": "password",
      "database_name": "name",
      "database_type": "type",
      "database_host": "host",
      "database_post": "post",
    },
  },
];

import React from 'react';
import {throttle} from './helpers';
const viewportContext = React.createContext({});

export default function ViewportProvider({children}) {
  const isServer = typeof window === 'undefined';
  const getWidth = () => !isServer ? window.innerWidth : 10;
  const getHeight = () => !isServer ? window.innerHeight : 10;
  const [width, setWidth] = React.useState(getWidth());
  const [height, setHeight] = React.useState(getHeight());

  React.useEffect(() => {
    const handleWindowResize = throttle(() => {
      setWidth(getWidth());
      setHeight(getHeight());
    }, 100);
    !isServer && window.addEventListener('resize', handleWindowResize);
    return () => !isServer && window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{width, height}}>
      {children}
    </viewportContext.Provider>
  );
}

export const useViewport = () => {
  const {width, height} = React.useContext(viewportContext);
  return {width, height};
};

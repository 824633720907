/* eslint-disable quotes */
// this is put into here so I can share these same handlers between my tests
// as well as my development in the browser. Pretty sweet!
import {rest} from 'msw'; // mock service worker
import fetchJson from '../lib/fetchJson';
import {
  mockFiles,
  getFileChildren,
  mockDuplicateFiles,
} from './files';
import {
  mockWorkspaces,
  postWorkspace,
} from './workspaces';
import {
  postLair,
  prodLair,
  publicLairData,
} from './lairs';
import {
  triggers,
  // eslint-disable-next-line no-unused-vars
  triggersContent,
} from './triggers';
import {
  secretsContent,
} from './secrets';
import {
  integrations,
  // integrationsContent,
} from './integrations';
import {v4 as uuidv4} from 'uuid';
import {mockProcessesData, mockProcessHistoryData} from './processes';
import {debugLog} from '../../helpers';

const completedHandlers = [
  rest.get(`*/users/:userId/invites$`, async(req, res, ctx) => {
    const response = [{
      workspace_id: 'anotherUserId',
      workspace_name: 'teamName',
    }];
    return res(ctx.json(response));
  }),
  rest.get(`*/users/current$`, async(req, res, ctx) => {
    const response = {
      id: 'userId',
      first_name: 'firstName',
      last_name: 'lastName',
      email: 'email@email',
      avatar: 'avatar',
    };
    return res(ctx.json(response));
  }),
  rest.post('*/workspaces/:workspaceId/join$', async(req, res, ctx) => {
    const response = {
      success: true,
    };
    return res(ctx.json(response));
  }),
  rest.get('*/users/:userId$', async(req, res, ctx) => {
    // eslint-disable-next-line no-unused-vars
    const {id} = await req.body;

    const response = {
      success: true,
    };
    return res(ctx.json(response));
  }),
  rest.post('*/workspaces', async(req, res, ctx) => {
    const body = await req.body;
    const {name} = body;
    let response;
    try {
      response = await fetchJson('https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/workspaces', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({name}),
      });
    } catch (error) {
      debugLog('USE STORED MOCK POST WORKSPACE');
      response = postWorkspace(name);
    }
    response = postWorkspace(name);
    return res(ctx.json(response));
  }),
  rest.post('*/workspaces/:path', async(req, res, ctx) => {
    const body = await req.body;
    const {path} = req.params;
    try {
      await fetchJson(`https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/workspaces/${path}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({body}),
      });
    } catch (error) {
      debugLog('USE STORED MOCK UPDATE WORKSPACE');
    }
    await new Promise(resolve => setTimeout(resolve, 3000));
    return res(ctx.json({}));
  }),
  rest.get('*/users/*/workspaces', async(req, res, ctx) => {
    let response;
    try {
      response = await fetchJson('https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/user/workspaces');
    } catch (error) {
      debugLog('USE STORED MOCK WORKSPACES');
      response = mockWorkspaces;
    }
    // New user
    response = [];
    return res(ctx.json(response));
  }),
  rest.delete('*/workspaces/:path', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return res(ctx.json({}));
  }),
  rest.put('*/files/*', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return res(ctx.json({success: true}));
  }),
  rest.put('*/files/:path', async(req, res, ctx) => {
    const body = await req.body;
    await new Promise(resolve => setTimeout(resolve, 500));
    return res(ctx.json(body));
  }),
  rest.post('*/lairs/*/processes/terminal', async(req, res, ctx) => {
    const response = uuidv4();
    await new Promise(resolve => setTimeout(resolve, 3000));
    return res(ctx.json({process_uuid: response}));
  }),
  rest.post('*/lairs$', async(req, res, ctx) => {
    const body = await req.body;
    const name = body.name;
    let response;
    try {
      response = await fetchJson('https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/lairs', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({name: name}),
      });
    } catch (error) {
      debugLog('USE MOCK LAIR RESPONSE');
      response = postLair(name);
    }
    return res(ctx.json(response));
  }),
  rest.post('*/lairs/*/stop', async(req, res, ctx) => {
    return res(ctx.json({}));
  }),
  rest.get('*/processes$', async(req, res, ctx) => {
    const lairId = req.url.searchParams.get('lair');
    const response = mockProcessesData(lairId);
    return res(ctx.json(response));
  }),
  rest.get('*/processes/:processId/logs', async(req, res, ctx) => {
    const {processId} = req.params;
    const response = mockProcessHistoryData(processId);
    return res(ctx.json(response));
  }),
  rest.post('*/files', async(req, res, ctx) => {
    const body = await req.body;
    let metadata;
    let file;
    let content = '';
    if (body instanceof FormData) {
      metadata = JSON.parse(body.get('metadata[]'));
      file = body.get('files[]');
    } else {
      // content is not supporting in jest tests..
      metadata = JSON.parse(body['metadata[]']);
      file = body['files[]'];
      content = await file.text(); // TODO: handle image files instead of text
    }

    const id = metadata.id;
    const name = metadata.name;
    const isDirectory = metadata.is_directory;
    const response = [
      {
        'id': id,
        'children': [],
        'editable': true,
        'is_directory': isDirectory,
        'is_lair_root': false,
        'is_workspace_root': false,
        'is_ws_file': false,
        'name': name,
        'parent': 'abc123',
        'public_url': '',
        'content': content,
      },
    ];
    await new Promise(resolve => setTimeout(resolve, 1500));

    return res(ctx.json(response));
  }),
  rest.get('*/files$', async(req, res, ctx) => {
    let response;
    try {
      response = await fetchJson('https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/files');
    } catch (error) {
      debugLog('USE STORED MOCK FILES');
      response = mockFiles;
    }
    // response = mockFiles; // TODO needed for integrations
    return res(ctx.json(response));
  }),
  rest.get('*/files/:path', async(req, res, ctx) => {
    const {path} = req.params;
    const getContents = req.url.searchParams.get('contents');
    if (path) {
      if (getContents) {
        // const response = JSON.stringify(triggersContent);
        const response = secretsContent;
        await new Promise(resolve => setTimeout(resolve, 10));
        return res(ctx.text(response));
      } else {
        const response = getFileChildren();
        await new Promise(resolve => setTimeout(resolve, 500));
        return res(ctx.json(response));
      }
    }
  }),
  rest.get('*/integrations/metadata', async(req, res, ctx) => {
    const response = integrations;
    await new Promise(resolve => setTimeout(resolve, 3000));
    return res(ctx.json(response));
  }),
  rest.get('*/triggers/metadata', async(req, res, ctx) => {
    const response = triggers;
    await new Promise(resolve => setTimeout(resolve, 3000));
    return res(ctx.json(response));
  }),
  rest.post('*/files/rename', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 3000));

    return res(ctx.json({}));
  }),
  rest.post('*/files/move', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 3000));

    return res(ctx.json({}));
  }),
  rest.delete('*/files/*', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return res(ctx.json({}));
  }),
  rest.post('*/files/clone', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 1500));
    return res(ctx.json(mockDuplicateFiles));
  }),
  rest.post('*/lairs/:lairId/triggers/:triggerId/manual$', async(req, res, ctx) => {
    await new Promise(resolve => setTimeout(resolve, 1500));
    return res(ctx.json({}));
  }),
  rest.post('*/deploy/:path', async(req, res, ctx) => {
    const {path} = req.params;
    const id = path ? path.split('/')[path.split('/').length - 1] : null;
    try {
      await fetchJson(`https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/deploy/${id}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
      });
    } catch (error) {
      debugLog('USE MOCK LAIR DEPLOY RESPONSE');
    }
    return res(ctx.json({}));
  }),
  rest.get('*/openprod/:path', async(req, res, ctx) => {
    const {path} = req.params;
    const id = path ? path.split('/')[path.split('/').length - 1] : null;

    let response;
    try {
      response = await fetchJson(`https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/files/${id}/prod`);
    } catch (error) {
      debugLog('USE MOCK PROD LAIR RESPONSE');
      response = prodLair();
    }
    return res(ctx.json(response));
  }),
  rest.get('*/openprod/:path', async(req, res, ctx) => {
    const {path} = req.params;
    const id = path ? path.split('/')[path.split('/').length - 1] : null;

    let response;
    try {
      response = await fetchJson(`https://8bf85ca1-8cd2-4cdc-bf21-10e431f0ae38.mock.pstmn.io/files/${id}/prod`);
    } catch (error) {
      debugLog('USE MOCK PROD LAIR RESPONSE');
      response = prodLair();
    }
    return res(ctx.json(response));
  }),
  rest.get('*/socket.io/*', async(req, res, ctx) => {
    return res(ctx.json({
    }));
  }),
  rest.get('*/integrations/socket.io/*$', async(req, res, ctx) => {
    debugLog('SOCKETIO');
    const response = {
      success: true,
    };
    return res(ctx.json(response));
  }),
  rest.get('*/lairs/:id', async(req, res, ctx) => {
    // eslint-disable-next-line no-unused-vars
    const {id} = req.params;
    return res(ctx.json({
      endpoint: null, // null means unpublished
    }));
  }),
  rest.post('*/lairs/:id', async(req, res, ctx) => {
    const {id} = req.params;
    return res(ctx.json({
      endpoint: `${id}.wayscript.io`,
    }));
  }),
  rest.delete('*/lairs/:id', async(req, res, ctx) => {
    // eslint-disable-next-line no-unused-vars
    const {id} = req.params;
    return res(ctx.json({
      endpoint: null,
    }));
  }),
  rest.get('*/lair/:id/public', async(req, res, ctx) => {
    return res(ctx.json(publicLairData));
  }),
  rest.delete('*/files/lairs/:id/secrets/*$', async(req, res, ctx) => {
    return res(ctx.json({
      success: true,
    }));
  }),
  rest.post('*/files/lairs/:id/secrets$', async(req, res, ctx) => {
    return res(ctx.json({
      success: true,
    }));
  }),
];

const developingHandlers = [
  rest.get('*/users/apikey', async(req, res, ctx) => {
    return res(ctx.json({
      api: 'abc123',
    }));
  }),
  rest.put('*/users/apikey', async(req, res, ctx) => {
    return res(ctx.json({
      api: 'newapikey',
    }));
  }),
  rest.put('*/workspaces/:id/billing', async(req, res, ctx) => {
    debugLog('BILLING', req, res);
    return res(ctx.json({
      success: true,
    }));
  }),
  rest.put('*/workspaces/:id/upgrade', async(req, res, ctx) => {
    debugLog('UPGRADE', req, res);
    return res(ctx.json({
      success: true,
    }));
  }),
  rest.put('*/workspaces/:id/boost', async(req, res, ctx) => {
    debugLog('BOOST', req, res);
    return res(ctx.json({
      success: true,
    }));
  }),
  rest.get('*/workspaces/:id/user-groups', async(req, res, ctx) => {
    debugLog('GET USER GROUPS', req, res);
    return res(ctx.json({
      groups: [{id: uuidv4(), name: 'Marketing', members: []}],
    }));
  }),
  rest.put('*/workspaces/:id/user-groups', async(req, res, ctx) => {
    debugLog('USER GROUPS', req, res);
    return res(ctx.json({
      success: true,
    }));
  }),
  rest.post('*/workspaces/:id/user-groups', async(req, res, ctx) => {
    debugLog('NEW USER GROUP', req, res);
    return res(ctx.json({id: uuidv4(), name: 'Test add group', members: []}));
  }),
  rest.delete('*/workspaces/:id/user-groups', async(req, res, ctx) => {
    debugLog('DELETE USER GROUP', req, res);
    return res(ctx.json({
      success: true,
    }));
  }),
  rest.get('*/lairs/:id/endpoints/public', async(req, res, ctx) => {
    debugLog('GET IS PUBLIC ENDPOINTS', req, res);
    return res(ctx.json({isPublic: false}));
  }),
  rest.post('*/lairs/:id/endpoints/public', async(req, res, ctx) => {
    debugLog('MAKE ENDPOINTS PUBLIC', req, res);
    return res(ctx.json({success: true}));
  }),
];

let handlers;
switch (process.env.NEXT_PUBLIC_MOCK) {
case 'hybrid':
  handlers = developingHandlers;
  break;
case 'all':
default:
  handlers = completedHandlers.concat(developingHandlers);
  break;
}

export {handlers};

import React, {useEffect, useState} from 'react';
import {useStore} from '../app/store';
import {AnimatePresence} from 'framer-motion';
import {Provider} from 'react-redux';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import theme from '../theme';
import ViewportProvider from '../utils/ViewportProvider';
import {DOMAIN} from '../app/constants';
import {datadogRum} from '@datadog/browser-rum';
import fetchJson from '../lib/fetchJson';
import {wayscriptBackendDomain} from '../../helpers';
import useSegment from '../lib/useSegment';

function handleExitComplete() {
  if (typeof window !== 'undefined') {
    window.scrollTo({top: 0});
  }
}

export default function App({Component, pageProps}) {
  const store = useStore(pageProps.initialReduxState);
  const windowDefined = typeof window !== 'undefined';
  useSegment();
  if (windowDefined) window.store = store;

  const [version, setVersion] = useState(null);
  const getVersion = async() => {
    const url = '/auth-service/health';
    const {version} = await fetchJson(url, {method: 'GET', headers: {'Content-Type': 'application/json'}});
    setVersion(version);
  };

  useEffect(() => {
    // set up datadog RUM session replay tracking
    if (!version) {
      getVersion();
      return;
    }
    if (!windowDefined || !wayscriptBackendDomain.includes(DOMAIN)) return;
    datadogRum.init({
      applicationId: 'db3b63d3-4433-4783-8f03-f1b9bad67859',
      clientToken: 'pub5ec0cc3acaccd12de2ce974446abfbd0',
      site: 'datadoghq.com',
      service: 'wayscriptx',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: version,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
      trackViewsManually: true,
      // TODO connect RUM to backend tracing
      // allowedTracingOrigins: ["https://api.example.com", /https:\/\/.*\.my-api-domain\.com/]
    });

    datadogRum.startSessionReplayRecording();
  }, [windowDefined, version]);

  return (
    <ViewportProvider>
      <Provider store={store}>
        <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
          <ChakraProvider resetCSS theme={theme}
            options={{
              useSystemColorMode: true,
            }}
          >
            <Component {...pageProps} />
          </ChakraProvider>
        </AnimatePresence>
      </Provider>
    </ViewportProvider>
  );
}

async function main() {
  if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
    if (typeof window === 'undefined') return;
    if (['all', 'hybrid'].includes(process.env.NEXT_PUBLIC_MOCK)) {
      const {worker} = require('../mocks/browser');
      const serviceWorker = {
        url: '/mockServiceWorker.js',
      };
      if (process.env.NEXT_PUBLIC_MOCK === 'hybrid') {
        // if there's no server-handler match, make the real request
        serviceWorker.onUnhandledRequest = 'bypass';
      }
      await worker.start({
        serviceWorker,
      });
    }
  }
}

main();

const mockFilesForTests = [
  {
    id: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    name: 'test_workspace',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-root',
    children: [
      'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
      '74fbe87d-204c-48c1-8391-ce2a358dc6af',
      'wayscript-48c1-8391-ce2a358dc6af',
    ],
    is_workspace_root: true,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'wayscript-48c1-8391-ce2a358dc6af',
    name: '.wayscript',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    children: [
      'integrations-47fa-8954-9f2b736111e9',
      'img-47fa-8954-9f2b736111e9',
      'settings-47fa-8954-9f2b736111e9',
      'alerts-57f3-47fa-8954-9f2b736111e9',
    ],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'img-47fa-8954-9f2b736111e9',
    name: 'img',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [
      // img files
    ],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'settings-47fa-8954-9f2b736111e9',
    name: 'settings',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
    name: 'test_lair',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    children: [
      '4742a09d-7c0a-4348-b316-485937db8bee',
      '64fae87d-204c-48c1-8391-ce2a358dc6af',
      'triggers-57f3-47fa-8954-9f2b736111e9',
      'b6f21263-68df-4f2d-86cc-e9645031a9bb',
    ],
    is_workspace_root: false,
    is_lair_root: true,
    is_directory: true,
  },
  {
    id: 'integrations-47fa-8954-9f2b736111e9',
    name: 'integrations',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: 'triggers-57f3-47fa-8954-9f2b736111e9',
    name: '.triggers',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: 'alerts-57f3-47fa-8954-9f2b736111e9',
    name: 'alerts',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: '4742a09d-7c0a-4348-b316-485937db8bee',
    name: 'time.py',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: '64fae87d-204c-48c1-8391-ce2a358dc6af',
    name: 'a',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
    children: ['c5676374-57f3-47fa-8954-9f2b736111e9'],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'c5676374-57f3-47fa-8954-9f2b736111e9',
    name: 'random.py',
    parent: '64fae87d-204c-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: '74fbe87d-204c-48c1-8391-ce2a358dc6af',
    name: 'global',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'b6f21263-68df-4f2d-86cc-e9645031a9bb',
    name: 'delete_test.py',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
    children: [],
    editable: true,
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
];

const mockFiles = [
  {
    id: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    name: 'test_workspace',
    parent: 'root',
    children: ['wayscript-48c1-8391-ce2a358dc6af'],
    is_workspace_root: true,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'wayscript-48c1-8391-ce2a358dc6af',
    name: '.wayscript',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    children: [
      'integrations-47fa-8954-9f2b736111e9',
      'img-47fa-8954-9f2b736111e9',
      'settings-47fa-8954-9f2b736111e9',
      'alerts-57f3-47fa-8954-9f2b736111e9',
    ],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'img-47fa-8954-9f2b736111e9',
    name: 'img',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [
      // img files
    ],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'settings-47fa-8954-9f2b736111e9',
    name: 'settings',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: 'integrations-47fa-8954-9f2b736111e9',
    name: 'integrations',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  {
    id: 'alerts-57f3-47fa-8954-9f2b736111e9',
    name: 'alerts',
    parent: 'wayscript-48c1-8391-ce2a358dc6af',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
  // {
  //   id: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
  //   name: 'test_lair',
  //   parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
  //   children: [
  //     // '4742a09d-7c0a-4348-b316-485937db8bee',
  //     // '64fae87d-204c-48c1-8391-ce2a358dc6af',
  //     // 'triggers-57f3-47fa-8954-9f2b736111e9', // triggers
  //   ],
  //   is_workspace_root: false,
  //   is_lair_root: true,
  //   is_directory: true,
  // },
  // {
  //   id: '4742a09d-7c0a-4348-b316-485937db8bee',
  //   name: 'time.py',
  //   parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
  //   children: [],
  //   is_workspace_root: false,
  //   is_lair_root: false,
  //   is_directory: false,
  // },
  // {
  //   id: '64fae87d-204c-48c1-8391-ce2a358dc6af',
  //   name: 'a',
  //   parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
  //   children: ['c5676374-57f3-47fa-8954-9f2b736111e9'],
  //   is_workspace_root: false,
  //   is_lair_root: false,
  //   is_directory: true,
  // },
  // {
  //   id: 'c5676374-57f3-47fa-8954-9f2b736111e9',
  //   name: 'random.py',
  //   parent: '64fae87d-204c-48c1-8391-ce2a358dc6af',
  //   children: [],
  //   is_workspace_root: false,
  //   is_lair_root: false,
  //   is_directory: false,
  // },
  // {
  //   id: 'triggers-57f3-47fa-8954-9f2b736111e9',
  //   name: 'triggers',
  //   parent: '64fae87d-204c-48c1-8391-ce2a358dc6af',
  //   children: [],
  //   is_workspace_root: false,
  //   is_lair_root: false,
  //   is_directory: false,
  // },
  // {
  //   id: 'triggers-57f3-47fa-8954-9f2b736111e9',
  //   name: 'triggers',
  //   parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
  //   children: [],
  //   is_workspace_root: false,
  //   is_lair_root: false,
  //   is_directory: false,
  // },
];

export const getFileChildren = () => {
  return [
    {
      id: 'triggers-57f3-47fa-8954-9f2b736111e9',
      name: '.triggers',
      parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
      children: [],
      is_workspace_root: false,
      is_lair_root: false,
      is_directory: false,
    },
  ];
};

const mockDuplicateFiles = [
  {
    id: '64fae87d-204c-48c1-8391-ce2a358dc6af-duplicated',
    name: 'a_duplicated',
    parent: 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    children: ['c5676374-57f3-47fa-8954-9f2b736111e9-duplicated'],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: true,
  },
  {
    id: 'c5676374-57f3-47fa-8954-9f2b736111e9-duplicated',
    name: 'random.py',
    parent: '64fae87d-204c-48c1-8391-ce2a358dc6af-duplicated',
    children: [],
    is_workspace_root: false,
    is_lair_root: false,
    is_directory: false,
  },
];

export {mockFiles, mockFilesForTests, mockDuplicateFiles};

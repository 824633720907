/* eslint-disable quotes */
import {v4 as uuidv4} from 'uuid';
export const postLair = name => {
  const id = uuidv4();
  return {
    "id": id,
    "name": name,
    "lair_manager": "123e4567-e89b-12d3-a456-426614174000",
    "created_date": "2021-06-01",
    "last_run_date": "2021-06-04",
    "file_object": {
      "id": id,
      "name": name,
      "parent": "fdbf3521-ba9c-4364-81f7-08bb2607507a", // same workspace id as used in mock files
      "children": [
        // "4742a09d-7c0a-4348-b316-485937db8bee", // file 'time.py'
        // "64fae87d-204c-48c1-8391-ce2a358dc6af", // folder 'a'
        "triggers-57f3-47fa-8954-9f2b736111e9", // events
      ],
      "is_storage_root": false,
      "is_workspace_root": false,
      "is_lair_root": true,
      "is_directory": true,
    },
  };
};

export const mockLairsForTests = [{
  "id": 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
  "name": "test_lair",
  "lair_manager": "123e4567-e89b-12d3-a456-426614174000",
  "created_date": "2021-06-01",
  "last_run_date": "2021-06-04",
  "file_object": {
    "id": 'fdbf3521-ba9c-4364-81f7-08bb2607507a-lair',
    "name": name,
    "parent": "fdbf3521-ba9c-4364-81f7-08bb2607507a", // same workspace id as used in mock files
    "children": [
      "4742a09d-7c0a-4348-b316-485937db8bee",
      "64fae87d-204c-48c1-8391-ce2a358dc6af",
      "triggers-57f3-47fa-8954-9f2b736111e9", // events
    ],
    "is_storage_root": false,
    "is_workspace_root": false,
    "is_lair_root": true,
    "is_directory": true,
  },
}];

// TODO use in server-handler
export const prodLair = () => {
  const id = uuidv4();
  return [
    {
      id: id + 'deploy',
      name: 'test.prod',
      parent: 'parent',
      children: [
        'triggers-57f3-47fa-1234-9f2b736111e9',
      ],
      is_workspace_root: false,
      is_lair_root: true,
      is_directory: true,
    },
    {
      id: 'triggers-57f3-47fa-1234-9f2b736111e9',
      name: 'eventstest',
      parent: id + 'deploy',
      children: [],
      is_workspace_root: false,
      is_lair_root: false,
      is_directory: false,
    },
  ];
};

export const publicLairData = {
  name: 'Public Lair',
  content: '# Public lair Readme content',
};

import {v4 as uuid} from 'uuid';
export const mockWorkspaces = [
  {
    'id': 'fdbf3521-ba9c-4364-81f7-08bb2607507a',
    'name': 'test_workspace',
  },
];

export const postWorkspace = name => ({
  // 'id': uuid(),
  'id': 'fdbf3521-ba9c-4364-81f7-08bb2607507a', // same uuid as used in mock files
  'name': name,
});

export const mockWorkspaceMembers = [{
  id: '9b4b1ad6-6243-49a5-ab30-d0de6eef0d43',
  name: 'Abigail Swarth',
  email: 'abigail@wayscript.com',
  role: 'Admin',
  avatar: 'https://media.istockphoto.com/photos/-picture-id1089409024?k=6&m=1089409024&s=612x612&w=0&h=omadSOo9co7t6YMqdp6U_VvV9w66whLQzd5HDn8suiY=',
},
{
  id: uuid(),
  name: 'Team Member',
  email: 'teammember@wayscript.com',
  role: 'Editor',
  avatar: 'https://www.warrenphotographic.co.uk/photography/bigs/39614-Bulldog-puppy-portrait-white-background.jpg',
}];
